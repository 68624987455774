exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-2-d-about-tsx": () => import("./../../../src/pages/2DAbout.tsx" /* webpackChunkName: "component---src-pages-2-d-about-tsx" */),
  "component---src-pages-2-d-home-tsx": () => import("./../../../src/pages/2DHome.tsx" /* webpackChunkName: "component---src-pages-2-d-home-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-currenthobbyproject-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/currenthobbyproject.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-currenthobbyproject-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-dicegame-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/dicegame.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-dicegame-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-dotgame-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/dotgame.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-dotgame-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-dreamingrealcinematic-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/dreamingrealcinematic.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-dreamingrealcinematic-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-fs-22-birdfeeder-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/fs22birdfeeder.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-fs-22-birdfeeder-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-fs-22-dronedelivery-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/fs22dronedelivery.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-fs-22-dronedelivery-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-internship-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/internship.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-internship-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-lasttrain-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/lasttrain.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-lasttrain-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-mazegeneration-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/mazegeneration.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-mazegeneration-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-mirrorroom-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/mirrorroom.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-mirrorroom-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-miscart-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/miscart.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-miscart-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-pathfindingnetworking-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/pathfindingnetworking.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-pathfindingnetworking-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-portfoliosite-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/portfoliosite.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-portfoliosite-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-portfoliositeversiontwo-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/portfoliositeversiontwo.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-portfoliositeversiontwo-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-vulkanrendering-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/vulkanrendering.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-vulkanrendering-mdx" */),
  "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-windowsclicktracker-mdx": () => import("./../../../src/pages/projects/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/projects/windowsclicktracker.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-fields-slug-tsx-content-file-path-src-projects-windowsclicktracker-mdx" */)
}

